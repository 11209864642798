import instance from '@/utils/request'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const TOKEN_KEY = '$_TARGET_FIGMA_FRONT_TOKEN_$'
const INFO_KEY = '$_TARGET_FIGMA_FRONT_USER_INFO_$'
const LOG_KEY = '$_TARGET_FIGMA_FRONT_DEV_LOG_$'

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem(TOKEN_KEY),
    info: localStorage.getItem(INFO_KEY) ? JSON.parse(localStorage.getItem(INFO_KEY)) : {},
    messageList: [],
    unreads: 0,
    platformInfo: {},
    globalLoading: false,
    requestQueue: [],
    logList: localStorage.getItem(LOG_KEY) ? JSON.parse(localStorage.getItem(LOG_KEY)) : {},
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getInfo(state) {
      return state.info
    },
    getMessageList(state) {
      return state.messageList
    },
    getUnreads(state) {
      return state.unreads
    },
    getPlatformInfo(state) {
      return state.platformInfo
    },
    getGlobalLoading(state) {
      return state.globalLoading
    },
    getRequestQueue(state) {
      return state.requestQueue
    },
    getLogList(state) {
      return state.logList
    },
  },
  mutations: {
    setToken(state, val) {
      state.token = val
    },
    setInfo(state, val) {
      state.info = val
    },
    setMessageList(state, val, clear) {
      clear && (state.messageList = [])
      val && state.messageList.unshift(val)
    },
    setUnreads(state, val) {
      state.unreads = val
    },
    setPlatformInfo(state, val) {
      state.platformInfo = val
    },
    setGlobalLoading(state, val) {
      state.globalLoading = val
    },
    setRequestQueue(state, val) {
      state.requestQueue.push(val)
    },
    resetRequestQueue(state, val = []) {
      state.requestQueue = val
    },
    setLogList(state, { key, value }) {
      if (!state.logList[key]) {
        state.logList[key] = []
      }
      state.logList[key].push(value)
      localStorage.setItem(LOG_KEY, JSON.stringify(state.logList))
    },
    clearLogList(state) {
      state.logList = {}
      localStorage.removeItem(LOG_KEY)
    },
  },
  actions: {
    setTokenSync(context, val) {
      context.commit('setToken', val)
      sessionStorage.setItem(TOKEN_KEY, val)
      // localStorage.setItem(TOKEN_KEY, val)
    },
    setInfoSync(context, val) {
      context.commit('setInfo', val)
      localStorage.setItem(INFO_KEY, JSON.stringify(val))
    },
    setMessageListSync(context, messageObject, clear) {
      context.commit('setMessageList', messageObject, clear)
    },
    setUnreadsSync(context, val) {
      context.commit('setUnreads', val)
    },
    async setPlatformInfoSync(context) {
      const { data } = await instance.get('/ListPlatformInformation')

      document.title = data.website_title
      context.commit('setPlatformInfo', data)

      context.commit({
        type: 'setLogList',
        key: 'website_logo',
        value: {
          title: '获取logo-url: ' + data.website_logo,
          date: new Date(),
        },
      })
    },
    setGlobalLoadingSync(context, val) {
      context.commit('setGlobalLoading', val)
    },
    setRequestQueueSync(context, val) {
      context.commit('setRequestQueue', val)
    },
    resetRequestQueueSync(context, controllerUrls = []) {
      /**
       * controllerUrls: Array<AbortController> 传入一个数组，表示要停止url为哪些的请求控制器
       * AbortController: {
       *  signal: {
       *   ...,
       *   url: '该控制器所对应的接口url'
       *  }
       * }
       */
      const newRequestQueue = []
      if (controllerUrls.length) {
        context.state.requestQueue.forEach((item) => {
          if (!controllerUrls.includes(item.signal.url)) {
            newRequestQueue.push(item)
          } else {
            item.abort()
          }
        })
      }
      context.commit('resetRequestQueue', newRequestQueue)
    },
  },
})

/**
 * store: state.freeTagQueue（Array<Function>） 表示当前所有需要执行的item对应的回调函数
 * freeTagTimer: 用于重复执行回调的定时器
 *
 * 1️⃣ 当list页面组件接收到响应的列表数据时：
 * - 通过v-if渲染每个必要的free-tag组件
 *   - 在free-tag组件的created钩子中，将回调函数push到store.freeTagQueue中
 * - 调用 store: mutations.runFreeTagQueue()：
 *   - 开启freeTagTimer定时器：遍历state.freeTagQueue，执行每个回调函数（每隔1秒重复一次）
 * 2️⃣ 当list页面组件卸载时/当前标签页失活时：
 * - 清空 store: state.freeTagQueue 数组
 * - 停止freeTagTimer定时器
 * 3️⃣ 当前标签页再次激活时：
 * - 从 1️⃣ 开始重复计时器逻辑
 */
